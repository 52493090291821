jQuery(function($){
  'use strict';


  var $win        = $(window),
      $body       = $('body'),
      easing      = 'easeOutQuart',
      scrollSpeed = 500;

  var loading_flg = false;

  /**
   * IEでobject-fitを適用
   */
  objectFitImages();

  $win.on('load', function() {

    // ページ遷移時のアンカースクロール
    pageAnchorScroll();

    // ローディング完了後fadeIn表示
    if ( !loading_flg ) {
      Loading();
    }

  });


  // 読み込みが遅い場合、2秒後に強制的に表示
  setTimeout(function () {
    if ( !loading_flg ) {
      Loading();
      console.log('timeout');
    }
  }, 2000);

  function Loading() {
    loading_flg = true;
    $body.addClass('is-load');
  }


  /**
   * ページ遷移時のアンカースクロール
   */
  function pageAnchorScroll() {
    var url = $(location).attr('href');
    if (url.indexOf('?id=') == -1) {
    } else {
      var url_sp = url.split('?id=');
      var hash   = '#' + url_sp[url_sp.length - 1];
      var tgt    = $(hash);

      var headerHeight = $('.header').outerHeight();
      var pos    = tgt.offset().top - headerHeight + 1;
      $('html, body').animate({scrollTop:pos}, scrollSpeed, easing);
    }
  }


  /**
   * アンカーリンク
   */
  anchorLink();
  function anchorLink() {
    $('a.scroll[href^="#"]').on("click", function() {
      var href = $(this).attr("href");
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top;
      $("body,html").animate({ scrollTop: position }, scrollSpeed, easing);
      return false;
    });
  }


  /**
   * ヘッダー
   * ハンバーガーメニューによるナビ開閉
   */
  headerNav();
  function headerNav() {
    var $headerNav     = $('.headerNav'),
    $headerNavMenu = $('.headerNavMenu'),
    navFlag        = false,
    navScrollpos;

    $headerNavMenu.on('click', function(){
      if (navFlag) {
        // ナビを閉じる
        headerNavClose();
      } else {
        // ナビを開く
        headerNavOpen();
      }
    });

    // ナビを開く
    function headerNavOpen(){
      // 現在のスクロール位置を取得
      navScrollpos = $win.scrollTop();
      $body.addClass('-navOpen').css({'top': -navScrollpos});
      $headerNav.stop().animate(
        {
          top: '0%',
          opacity: 1
        },
        {
          duration: 400
        },
        'easeOutExpo'
      );
      navFlag = true;
    }

    // ナビを閉じる
    function headerNavClose(){
      // メニューの固定表示を解除
      $body.removeClass('-navOpen').css({'top': 0});
      //元のスクロール位置へ移動
      window.scrollTo(0, navScrollpos);
      $headerNav.stop().animate(
        {
          top: '-100%',
          opacity: 0
        },
        {
          duration: 400
        },
        'easeOutExpo'
      );
      navFlag = false;
    }

  }


  /**
   * Scroll Animation
   */
  ScrollAddClass();
  function ScrollAddClass() {
    var $animeTarget = document.querySelectorAll('.js-target');
    var controller = new ScrollMagic.Controller();
    var defaltTriggerRatio = '0.8';

    for (var i=0; i<$animeTarget.length; i++) {

      var scene = new ScrollMagic.Scene({
        triggerElement: $animeTarget[i],
        triggerHook: defaltTriggerRatio,
        reverse: false,
        offset: 0
      })
      .setClassToggle($animeTarget[i], 'js-action')
      .addTo(controller);
    }
  }


  /**
   * WORKS
   * FAQ slideToggle
   */
   SlideToggleFAQ();
   function SlideToggleFAQ() {
     var $question = $('.faq__Question');
     $question.click(function(){
       jQuery(this).next('.faq__Answer').slideToggle();
       jQuery(this).find('.faq__Toggle').toggleClass('is-open');
     });
   }

});
